.footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  line-height: 24px;
  opacity: 0.6;

  &__link {
    margin-left: 5px;
    font-size: 24px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}
