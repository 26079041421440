.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding: 40px;

  &__wrapper {
      max-width: 900px;
  }

  &__title {
    width: fit-content;
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1;

    span {
      margin-left: 10px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    margin-left: -20px;
    margin-top: -20px;
    list-style: none;
  }

  &__item {
    margin-left: 20px;
    margin-top: 20px;

    img {
      width: 206px;
      height: 206px;
      object-fit: contain;
      object-position: bottom;
    }

    input {
      padding: 0;
      line-height: inherit;
      border: none;
    }
  }

  button {
    margin-right: 10px;
  }

  form.d-flex {
    margin-bottom: 40px;
  }

  div.card-text {
    margin-bottom: 1rem;
  }
}
