@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-latin-regular.woff2"),
    url("../fonts/lato-latin-regular.woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-latin-700.woff2"),
    url("../fonts/lato-latin-700.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-latin-700italic.woff2"),
    url("../fonts/lato-latin-700italic.woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
